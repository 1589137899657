window._ = require('lodash');

/**
 * We'll load jQuery and the Semantic jQuery plugin which provides support
 * for JavaScript based Semantic features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('../fomantic/dist/semantic.min');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/**
 * Sentry provides a high-quality error tracking to help us repair
 */

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.MIX_SENTRY_DSN !== 'null') {
    Sentry.init({
        dsn: process.env.MIX_SENTRY_DSN,
        integrations: [new Integrations.Vue({
            Vue: window.Vue,
            attachProps: true
        })]
    });
}
